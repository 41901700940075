import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
      <Card className="quote-card-view">
        <Card.Body>
          <blockquote className="blockquote mb-0">
            <p style={{ textAlign: "justify" }}>
              Hi Everyone, I am <span className="purple">Shubham Choubey </span>
              from <span className="purple"> Delhi, India.</span>
              <br />
              I am currently employed as a software developer at Coforge.
              <br />
              I have completed Btech  in ECE  at ADGITM
              Delhi.
              <br />
              <br />
              Apart from coding, some other activities that I love to do!
            </p>
            <ul>
              <li className="about-activity">
                <ImPointRight /> Playing Games
              </li>
              <li className="about-activity">
                <ImPointRight /> Watching Movies
              </li>
              <li className="about-activity">
                <ImPointRight /> Cooking
              </li>
            </ul>

            <p style={{ color: "rgb(155 126 172)" }}>
              "Practice Practice Practice and get good in doing things!"{" "}
            </p>
            <footer className="blockquote-footer">Shubham</footer>
          </blockquote>
        </Card.Body>
      </Card>
  );
}

export default AboutCard;
